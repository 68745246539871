import { createAction, props } from '@ngrx/store';
import {
  TSignupRequest,
  TVerifyEmailRequest,
  TSignInSuccessResponse,
  TSignInWithTelegramRequest,
  TSignInRequest,
  TResetPasswordRequest,
  TNewPasswordRequest,
} from "@revolve-app/app/core/metadata/types/auth.models";
import {TBaseSuccessResponse} from "@revolve-app/app/core/metadata/types/base.models";

export const AUTH_SIGN_IN = '[Auth] Sign In';
export const AUTH_SIGN_IN_WITH_TELEGRAM = '[Auth] Sign In With Telegram';
export const AUTH_SIGN_IN_SUCCESS = '[Auth] Sign In Success';
export const AUTH_SIGN_IN_FAIL = '[Auth] Sign In Fail';

export const AUTH_SIGN_OUT = '[Auth] Sign out';
export const AUTH_SIGN_OUT_SUCCESS = '[Auth] Sign out Success';


export const AUTH_SIGN_UP = '[Auth] Sign Up';
export const AUTH_SIGN_UP_SUCCESS = '[Auth] Sign Up Success';
export const AUTH_SIGN_UP_FAIL = '[Auth] Sign Up Fail';


export const AUTH_VERIFY_EMAIL = '[Auth] Verify Email';
export const AUTH_VERIFY_EMAIL_SUCCESS = '[Auth] Verify Email Success';
export const AUTH_VERIFY_EMAIL_FAIL = '[Auth] Verify Email Fail';

export const AUTH_VERIFY_PHONE_NUMBER = '[Auth] Verify Phone Number';
export const AUTH_RESEND_VERIFY_PHONE_NUMBER = '[Auth] Resend Verify Phone Number';
export const AUTH_CHECK_FORGOT_NUMBER = '[Auth] Check forgot Number';
export const AUTH_VERIFY_PHONE_NUMBER_SUCCESS = '[Auth] Verify Phone Number Success';
export const AUTH_RESEND_VERIFY_PHONE_NUMBER_SUCCESS = '[Auth] [Auth] Resend Verify Phone Number Success';
export const AUTH_RESEND_VERIFY_PHONE_NUMBER_FAIL = '[Auth] [Auth] Resend Verify Phone Number Fail';
export const AUTH_VERIFY_PHONE_NUMBER_FAIL = '[Auth] Verify Phone Number Fail';

export const RESET_PASSWORD_START = '[Auth] Reset Password';
export const RESET_PASSWORD_SUCCESS = '[Auth] Reset Password Success';
export const RESET_PASSWORD_FAIL = '[Auth] Reset Password Fail';

export const SET_NEW_PASSWORD_START = '[Auth] Set New Password';
export const SET_NEW_PASSWORD_SUCCESS = '[Auth]  Set New Password Success';
export const SET_NEW_PASSWORD_FAIL = '[Auth]  Set New Password Fail';

export const FETCH_SESSION_START = '[Auth] Fetch Session Start';
export const FETCH_SESSION_SUCCESS = '[Auth]  Fetch Session Success';
export const FETCH_SESSION_FAIL = '[Auth]  Fetch Session Fail';


export const fetchSession = createAction(
  FETCH_SESSION_START
);
export const fetchSessionSuccess = createAction(
  FETCH_SESSION_SUCCESS,
  props<{ session: any }>()
);
export const fetchSessionFailure = createAction(
  FETCH_SESSION_FAIL,
  props<{ error: any }>()
);

// ******************************** sign in *****************************
export const signIn = createAction(
  AUTH_SIGN_IN,
  props<{signInInput: TSignInRequest, redirectUrl: string}>()
);

export const signInWithTelegram= createAction(
  AUTH_SIGN_IN_WITH_TELEGRAM,
  props<{signInInput: TSignInWithTelegramRequest, redirectUrl: string}>()
);
export const signInSuccess = createAction(
  AUTH_SIGN_IN_SUCCESS,
  props<{payload: TSignInSuccessResponse, redirectUrl: string, queryParams?: any}>() // Replace `any` with your user model
);

// ******************************** sign in *****************************
export const signOut = createAction(
  AUTH_SIGN_OUT
);
export const signOutSuccess = createAction(
  AUTH_SIGN_OUT_SUCCESS
);

export const signInFailure = createAction(
  AUTH_SIGN_IN_FAIL
);

// ******************************** sign up *****************************

export const signUp = createAction(
  AUTH_SIGN_UP,
  props<{signUpInput: TSignupRequest, redirectUrl: string, queryParams?: any}>()
);

export const signUpSuccess = createAction(
  AUTH_SIGN_UP_SUCCESS,
  props<{payload: TBaseSuccessResponse, phoneNumber: string}>() // Replace `any` with your user model
);

export const signUpFailure = createAction(
  AUTH_SIGN_UP_FAIL
);

// ******************************** verification code *****************************

export const verifyEmail = createAction(
  AUTH_VERIFY_EMAIL,
  props<{verifyInput: TVerifyEmailRequest, redirectUrl: string}>()
);

export const verifyEmailSuccess = createAction(
  AUTH_VERIFY_EMAIL_SUCCESS,
  props<{payload: TBaseSuccessResponse}>() // Replace `any` with your user model
);

export const verifyEmailFailure = createAction(
  AUTH_VERIFY_EMAIL_FAIL
);

export const verifyPhoneNumber = createAction(
  AUTH_VERIFY_PHONE_NUMBER,
  props<{verifyInput: any, redirectUrl: string}>()
);
export const resendVerifyPhoneNumber= createAction(
  AUTH_RESEND_VERIFY_PHONE_NUMBER,
  props<{phoneNumber: any, callback: () => void}>()
);

export const checkForgotCode =  createAction(
  AUTH_CHECK_FORGOT_NUMBER,
  props<{verifyInput: any, redirectUrl: string}>()
);

export const verifyPhoneNumberSuccess = createAction(
  AUTH_VERIFY_PHONE_NUMBER_SUCCESS,
  props<{payload: any, redirectUrl: string}>()
);

export const verifyPhoneNumberFailure = createAction(
  AUTH_VERIFY_PHONE_NUMBER_FAIL
);


// ******************************** reset password *****************************

export const resetPasswordRequestStart = createAction(
  RESET_PASSWORD_START,
  props<{resetPasswordInput: TResetPasswordRequest,redirectUrl: string}>()
)

export const resetPasswordRequestFail = createAction(
  RESET_PASSWORD_FAIL
);

export const resendVerifyPhoneNumberFailure = createAction(
  AUTH_RESEND_VERIFY_PHONE_NUMBER_FAIL,
)

export const resetPasswordRequestSuccess = createAction(
  RESET_PASSWORD_SUCCESS,
  props<{payload: any, redirectUrl: string, phoneNumber: string}>()

)

export const resendVerifyPhoneNumberSuccess = createAction(
  AUTH_RESEND_VERIFY_PHONE_NUMBER_SUCCESS
)


// ******************************** reset password *****************************

export const setNewPasswordRequestStart = createAction(
  SET_NEW_PASSWORD_START,
  props<{setNewPasswordInput: TNewPasswordRequest,redirectUrl: string}>()
)

export const setNewPasswordRequestFail = createAction(
  SET_NEW_PASSWORD_FAIL
);

export const setNewPasswordRequestSuccess = createAction(
  SET_NEW_PASSWORD_SUCCESS,
  props<{payload: any, redirectUrl: string}>()

)
